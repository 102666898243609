import React from 'react';

const MXTSplashPage = () => {
  return (
    <div className="w-full h-screen bg-black px-6 sm:px-12 lg:px-24 py-12 text-white flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl gap-16">
        
        {/* Left Section: Title and Feature Description */}
        <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left space-y-8">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold font-theFutureMonoRegular tracking-tighter leading-tight" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            A digital drafting tool for music producers.
          </h1>
          <p className="text-lg sm:text-xl font-light leading-relaxed max-w-lg" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            Streamline your album drafting process with a digital workspace that lets you draft, play, edit, and share your albums with collaborators.
          </p>
        </div>

        {/* Right Section: Feature Highlights */}
        <div className="flex items-center justify-center md:justify-start">
          <ul className="text-sm sm:text-base font-light leading-relaxed space-y-6" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            <li className="flex items-start space-x-2">
              <span>🎵</span>
              <span>
                <strong>Draft:</strong> Draft albums in a fluid drag and drop interface.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span>🔊</span>
              <span>
                <strong>Play:</strong> Gapless playback lets you preview album flow.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span>✂️</span>
              <span>
                <strong>Edit:</strong> Built-in audio editor makes removing silence a breeze.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span>🚀</span>
              <span>
                <strong>Share:</strong> Send drafts to collaborators for feedback with a link.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span>🤖</span>
              <span>
                <strong>Automate:</strong> AI-powered workflows to eliminate busywork.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MXTSplashPage;