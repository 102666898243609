import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import UsernameEntryTextField from "./UsernameEntryTextField";
import auth from "../../services/auth";
import LightSmallSpinner from "../../components/loading/LightSmallSpinner";

const UsernameForm = (props) => {
  const fbAuth = firebase.auth();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(true);
  const [inputValid, setInputValid] = useState(false);
  const [user] = useAuthState(fbAuth);

  const handleUsernameChange = (username) => {
    setUsername(username);
  };

  const handleUsernameCheck = async (usernameToCheck) => {
    setLoading(true);
    try {
      const response = await auth.checkUsername(usernameToCheck);
      const isAvailable = response && !response.exists;
      setAvailable(isAvailable);
      setInputValid(isAvailable);
      return isAvailable;
    } catch (error) {
      console.error("Error during username check:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleUsernameSubmit = async (username) => {
    const isAvailable = await handleUsernameCheck(username);
    if (user && inputValid && isAvailable) {
      setLoading(true);
      try {
        const token = await user.getIdToken(true);
        const response = await auth.updateUsername(username, token);
        if (response && response.successful) {
          props.onUsernameSubmitted(username);
        } else {
          setAvailable(false);
          setInputValid(false);
        }
      } catch (error) {
        console.error("Error during username update:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const logicalRender = () => {
    return (
      <div className="h-6 mt-2">
        {loading && <LightSmallSpinner />}
        {!available && !inputValid && !loading && (
          <div className="font-sans text-red-500">Username is taken</div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center font-sans text-black dark:text-white bg-white dark:bg-black w-full max-w-md">
      <UsernameEntryTextField
        user={props.user}
        onUsernameChange={handleUsernameChange}
        onUsernameSubmit={handleUsernameSubmit}
        setInputValidity={setInputValid}
        setAvailable={setAvailable}
        loading={loading}
        available={available}
      />
      {logicalRender()}
    </div>
  );
};

export default UsernameForm;