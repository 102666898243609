import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import LightSmallSpinner from "../../loading/LightSmallSpinner";
import { useProfile } from "./context/ProfileContext";
import SetUsernameInputField from "./SetUsernameInputField";
import EditUsernameView from "./EditUsernameView";
import UsernameEditView from "../../../auth/UsernameEditView";

const SetUsernameView = ({ onCancel }) => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const {
    profileInfo,
    loading: profileLoading,
    error: profileError,
  } = useProfile();
  const [initialUsername, setInitialUsername] = useState("");
  const [available, setAvailable] = useState(true);
  const [inputValid, setInputValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (profileInfo && profileInfo.username) {
      setInitialUsername(profileInfo.username);
    }
  }, [profileInfo]);

  const handleUsernameCheck = async (usernameToCheck) => {
    // Mock async check for username availability
    return new Promise((resolve) => {
      setTimeout(() => {
        const isAvailable = usernameToCheck !== "takenusername"; // Replace with actual check
        setAvailable(isAvailable);
        resolve();
      }, 500);
    });
  };

  const handleUsernameSubmit = async () => {
    // Mock update username
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("Username updated to:", initialUsername);
        navigate("/settings");
        resolve();
      }, 500);
    });
  };

  if (loading || profileLoading) {
    return <LightSmallSpinner />;
  }

  return (
    <div className="flex justify-center items-center dark:bg-gray-900">
      <div className="w-full max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg">
        <h1 className="text-2xl font-semibold text-center mb-4">Update Username</h1>
        <div className="h-6">
          {(error || profileError) && (
            <div className="text-red-400 text-center">
              {error?.message || profileError?.message}
            </div>
          )}
        </div>
        {user && !loading && (
        //  <EditUsernameView   />
        <UsernameEditView />
        )}
      </div>
    </div>
  );
};

export default SetUsernameView;