import React from "react";
import { useNavigate } from "react-router-dom";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"; // For the ellipsis menu icon
import DeleteAlbumButton from "./dashboard/DeleteAlbumButton";

const DraftListView = ({ drafts, isEditMode }) => {
  const navigate = useNavigate();

  const draftsList = drafts.map((draft) => (
    <li
      key={draft.id}
      className="flex items-center justify-between p-3 border-b border-gray-700 hover:bg-gray-800 transition-colors duration-200 ease-in-out"
    >
      {/* Clickable area for navigation */}
      <button
        className="flex-grow py-2 px-4 text-left text-white font-semibold tracking-wide focus:outline-none"
        onClick={() => {
          navigate("/create/album", { state: { album: draft } });
        }}
      >
        <span className="ml-2 text-xl">{draft.name}</span>
      </button>

      {/* Show the delete button only when in edit mode */}
      {isEditMode && (
        <div className="pr-4">
          <DeleteAlbumButton album={draft} />
        </div>
      )}
    </li>
  ));

  return (
    <div className="my-4">
      <ul className="bg-gray-900 rounded-lg shadow-lg">{draftsList}</ul>
    </div>
  );
};

export default DraftListView;