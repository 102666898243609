import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "../column";
import initialData from "../initial-data";
import useDragDrop from "./hooks/useDragDrop";
import AddButton from "./AddButton";
import Uploader from "../../../file_mgmt/uploader/Uploader";
import useAlbumInfo from "./hooks/useAlbumInfo";
import useTrackManager from "./hooks/useTrackManager";
import useFileAndDraftSubscriptions from "./hooks/useFileAndDraftSubscriptions";
import useApplyAINaming from "./hooks/useApplyAINaming";

const CreateAlbumView = ({ album }) => {
  const [data, setData] = useState(initialData);
  const { artistId } = useAlbumInfo(album.docId);
  const addNewTrack = useTrackManager(album, artistId, data, setData);
  useFileAndDraftSubscriptions(album, setData);

  const { onDragStart, onDragEnd, homeIndex } = useDragDrop(
    data,
    album,
    setData,
    artistId
  );

  const { applyAINamingToAllTracks } = useApplyAINaming(data, album); // Use the new hook

  return (
    <div className="flex flex-col gap-4 p-4 md:flex-row md:gap-6">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {data.columnOrder.map((columnId, index) => {
          const column = data.columns[columnId];
          const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
          const isDropDisabled = index < homeIndex;

          return (
            <div
              key={column.id}
              className={`flex flex-col ${
                index === 0 ? "md:w-1/3 w-full" : "md:w-2/3 w-full"
              }`}
            >
              {index === 0 && (
                <div className="font-theFutureMonoRegular text-lg text-yellow-200 p-2 md:p-4">
                  {"/files"}
                </div>
              )}

              {index === 1 && (
                <AddButton
                  label="add track"
                  onClick={addNewTrack}
                  className="self-start px-5 mb-2"
                />
              )}

              {index === 0 && <Uploader />}

              <Column
                column={column}
                tasks={tasks}
                album={album}
                isDropDisabled={isDropDisabled}
              />
            </div>
          );
        })}
      </DragDropContext>

      {/* Add button to trigger AI Naming for all tracks */}
      {/* <button
        onClick={() => {
          console.log("AI naming button clicked");
          applyAINamingToAllTracks();
        }}
        className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition"
      >
        Apply AI Naming to All Tracks
      </button> */}
    </div>
  );
};

export default CreateAlbumView;
