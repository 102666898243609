import React, { useState, useEffect } from 'react';
import api from '../../../api';
import ArtistComponent from './ArtistComponent';
import ProfileHeader from './ProfileHeader';
import AddArtistComponent from './AddArtistComponent';

const ProfileDetailsView = ({ data }) => {
  const [editMode, setEditMode] = useState({});
  const [editedNames, setEditedNames] = useState({});

  useEffect(() => {
    if (data?.artists) {
      const initialEditMode = {};
      const initialEditedNames = {};
      data.artists.forEach((artist) => {
        initialEditMode[artist.id] = false;
        initialEditedNames[artist.id] = artist.name;
      });
      setEditMode(initialEditMode);
      setEditedNames(initialEditedNames);
    }
  }, [data]);

  const handleEditToggle = (artistId) => {
    setEditMode((prev) => ({ ...prev, [artistId]: !prev[artistId] }));
  };

  const handleNameChange = (artistId, newName) => {
    setEditedNames((prev) => ({ ...prev, [artistId]: newName }));
  };

  const handleUpdate = async (artistId) => {
    try {
      await api.updateArtistName(artistId, editedNames[artistId]);
      setEditMode((prev) => ({ ...prev, [artistId]: false }));
    } catch (error) {
      alert('Error updating artist name.'); // Simple error feedback
    }
  };

  const handleDeleteArtist = async (artistId) => {
    try {
      await api.deleteArtist(artistId);
      data.artists = data.artists.filter((artist) => artist.id !== artistId); // Update state
    } catch (error) {
      alert('Error deleting artist.'); // Simple error feedback
    }
  };

  if (!data) return <p>Loading...</p>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md text-gray-800">
      <ProfileHeader 
        username={data.username}
        bio={data.bio}
        created={data.created}
        is_followed={data.is_followed}
        is_seller={data.is_seller}
      />

      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2 text-gray-900">Artists</h3>
        <AddArtistComponent />
        {data.artists && data.artists.length > 0 ? (
          <ul className="space-y-4">
            {data.artists.map((artist) => (
              <ArtistComponent 
                key={artist.id}
                artist={{ ...artist, name: editedNames[artist.id] ?? artist.name }}
                isEditMode={editMode[artist.id]}
                onEditToggle={handleEditToggle}
                onNameChange={handleNameChange}
                onUpdate={handleUpdate}
                onDelete={handleDeleteArtist}
              />
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No artists data available.</p>
        )}
      </div>
    </div>
  );
};

export default ProfileDetailsView;