import React, { useState } from "react";

const WideButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState(props.text);

  const handleClick = (e) => {
    setLoading(true);
    props.lambda(e); // Pass the event to the lambda function
  };

  const handleLoading = () => {
    if (props.loading) {
      setButtonText("loading...");
    } else {
      setButtonText(props.text);
    }
  };

  // Base button classes for shared styles
  const baseButtonClasses =
    "flex items-center justify-center rounded-md font-theFutureMonoRegular border-2 col-span-1 transition duration-300 ease-in-out";

  // Black and white button styles, dark and light mode friendly
  const darkModeClasses =
    "dark:text-white dark:bg-black dark:border-white dark:hover:bg-white dark:hover:text-black";
  const lightModeClasses =
    "text-black bg-white border-black hover:bg-black hover:text-white";
  const buttonClasses = `${baseButtonClasses} py-2 px-4 w-48 h-10 ${lightModeClasses} ${darkModeClasses}`;

  return (
    <button
      onClick={handleClick} // Event is now properly passed
      className={buttonClasses}
      onMouseLeave={() => setButtonText(props.text)}
    >
      {buttonText}
    </button>
  );
};

export default WideButton;