import React from 'react';
import useFetchProfileData from '../hooks/useFetchProfileData';
import ProfileDetailsView from './ProfileDetailsView';
import Spinner from '../../loading/Spinner';


const ProfileView = () => {
  const { data, loading, error } = useFetchProfileData();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center">
          <Spinner /> {/* Loading Spinner */}
          <p className="text-gray-600 mt-4">Loading your profile, please wait...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }

  if (!data) {
    return <div className="text-center text-gray-500">No profile data available.</div>;
  }

  return (
    <div>
      <ProfileDetailsView data={data} />
    </div>
  );
};

export default ProfileView;