// ProfileHeader.jsx
import React from 'react';

const ProfileHeader = ({ username, bio, created, is_followed, is_seller }) => {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6 text-blue-900">Profile Details</h1>
      <h2 className="text-xl font-mono mb-4 text-blue-80 ">@{username}</h2>
      <p className="text-md mb-2"><span className="font-medium">Bio:</span> {bio || 'Not provided'}</p>
      <p className="text-md mb-2"><span className="font-medium">Account Created:</span> {new Date(created).toLocaleDateString()}</p>
      {/* <p className="text-md mb-4"><span className="font-medium">Followed by you:</span> {is_followed ? 'Yes' : 'No'}</p> */}
      <p className="text-md mb-4"><span className="font-medium">Seller Account:</span> {is_seller ? 'Yes' : 'No'}</p>
    </div>
  );
};

export default ProfileHeader;
