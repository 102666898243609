import React from "react";
import { Link } from "react-router-dom";

export const Title = () => {
  return (
    <Link to="/" className="flex items-center ">
      <span className="text-xl font-mono text-gray-800 dark:text-gray-200">
        {/* PHAZE */}
        Carnyx
      </span>
    </Link>
  );
};