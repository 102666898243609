import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { PhoneIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useProfile } from "./profile/context/ProfileContext";
import SetUsernameView from "./profile/SetUsernameView";

function UserInfoDisplay() {
  const auth = firebase.auth();
  const [user, loading] = useAuthState(auth);
  const [isEditing, setIsEditing] = useState(false);
  const {
    profileInfo,
    loading: profileLoading,
    error: profileError,
  } = useProfile();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (profileInfo && profileInfo.username) {
      setUsername(profileInfo.username);
    }
  }, [profileInfo]);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const displayValue = (value) => {
    return value || <span className="italic text-gray-400">Not set</span>;
  };

  if (loading || profileLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <ul className="space-y-2">
        <li className="flex items-center">
          <span className="font-theFutureMonoRegular flex items-center">
            {isEditing ? (
              <SetUsernameView onCancel={toggleEditMode} />
            ) : (
              <>
                <PencilIcon
                  className="h-5 w-5 text-gray-500 cursor-pointer"
                  onClick={toggleEditMode}
                />
                <span className="mr-2 px-2">{displayValue(username)}</span>
              </>
            )}
          </span>
        </li>
        <li className="flex items-center">
          <PhoneIcon className="h-5 w-5 mr-2" />
          <span className="font-theFutureMonoRegular">
            {displayValue(user.phoneNumber)}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default UserInfoDisplay;