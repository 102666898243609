import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

const db = firebase.firestore();

const useAlbumInfo = (albumId) => {
    const [albumInfo, setAlbumInfo] = useState(null);
    const [artistId, setArtistId] = useState(null); // New state for artist ID
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user] = useAuthState(firebase.auth());

    useEffect(() => {
        if (!user) {
            setError("No user logged in");
            setAlbumInfo(null);
            return;
        }

        if (!albumId) {
            setError("No album ID provided");
            setAlbumInfo(null);
            return;
        }

        setLoading(true);

        const albumRef = db.collection("studio").doc(user.uid).collection("drafts").doc(albumId);

        const unsubscribe = albumRef.onSnapshot(doc => {
            if (doc.exists) {
                setAlbumInfo(doc.data());
                setArtistId(doc.data().artist_id); // Set artist ID when document data changes
                setError(null);
            } else {
                setError("Album not found");
                setAlbumInfo(null);
            }
            setLoading(false);
        }, err => {
            setError(err.message);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [user, albumId]);

    return { albumInfo, artistId, loading, error }; // Return artist ID
};

export default useAlbumInfo;