import React, { useState } from 'react';
import { TrashIcon, PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'; // Updated import for Heroicons v2
import Spinner from '../../loading/Spinner';

const ArtistComponent = ({ artist, isEditMode, onEditToggle, onNameChange, onUpdate, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (artistId) => {
    setIsLoading(true);
    await onUpdate(artistId);
    setIsLoading(false);
  };

  const handleDeleteConfirm = async (artistId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${artist.name}?`);
    if (isConfirmed) {
      setIsLoading(true);
      await onDelete(artistId);
      setIsLoading(false);
    }
  };

  return (
    <li className="flex items-center justify-between py-2 border-b">
      {isEditMode ? (
        <input
          type="text"
          value={artist.name}
          className="flex-grow mr-2 p-2 border rounded focus:outline-none focus:border-blue-300"
          onChange={(e) => onNameChange(artist.id, e.target.value)}
          disabled={isLoading}
        />
      ) : (
        <span className={`flex-grow ${isLoading ? 'text-gray-400' : ''}`}>
          {artist.name}
        </span>
      )}

      <div className="flex items-center">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <button
              onClick={() => onEditToggle(artist.id)}
              className="mx-1 text-blue-500 hover:text-blue-600"
            >
              {isEditMode ? <XMarkIcon className="h-5 w-5" /> : <PencilIcon className="h-5 w-5" />} {/* Updated XIcon to XMarkIcon */}
            </button>
            {isEditMode && (
              <>
                <button
                  onClick={() => handleUpdate(artist.id)}
                  className="mx-1 text-green-500 hover:text-green-600"
                >
                  <CheckIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => handleDeleteConfirm(artist.id)}
                  className="mx-1 text-red-500 hover:text-red-600"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default ArtistComponent;