import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { firebaseAuth } from "../../provider/AuthProvider";
import { useAuthState } from "react-firebase-hooks/auth";
import { Title } from "./Title";
import MenuItems from "./menu/MenuItems";
import firebase from "firebase/compat/app";

const NavBar = () => {
  const { token } = useContext(firebaseAuth);
  const location = useLocation();
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const isRoot = location.pathname === "/";

  let altAuthPath;
  if (location.pathname === "/signin") {
    // altAuthPath = { path: "/signup", text: "sign up" };
  } else if (location.pathname === "/signup") {
    altAuthPath = { path: "/signin", text: "sign in" };
  }

  const useGradient = false; // Toggle for gradient background

  const backgroundClass = useGradient
    ? 'bg-gradient-to-r from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800'
    : 'bg-white dark:bg-black';

  return (
    <nav className={`w-full z-10 ${backgroundClass}`}>
      <div className="container">
        <div className="flex justify-between py-2 px-4 md:px-16 text-gray-900 dark:text-gray-200">
          <div className="flex-1">
            <Title />
          </div>
          <div className="flex-1 flex justify-end">
            <MenuItems user={user} altAuthPath={altAuthPath} isRoot={isRoot} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;