import React, { useContext } from "react";
import { firebaseAuth } from "../../provider/AuthProvider";
import useUserProfile from "../../hooks/useUserProfile";
import WideButton from "../buttons/WideButton";
import ContactEmail from "./ContactEmail";
import StorageUsage from "../file_mgmt/drive/hooks/StorageUsage";
import APIConfig from "../../APIConfig";
import ProfileView from "../events/profiles/ProfileView";
import UserInfoDisplay from "./UserInfoDisplay";
import SetUsernameView from "./profile/SetUsernameView";

function Settings() {
  const { handleSignout } = useContext(firebaseAuth);
  const {
    artistName,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
  } = useUserProfile();

  const displayStorage = () => {
    if (APIConfig.airtrak() === true || APIConfig.studio() === true) {
      return (
        <div className="p-6 bg-gray-100 dark:bg-gray-800 rounded-lg">
          <StorageUsage />
          <ProfileView />
        </div>
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8 rounded-lg">
      <h1 className="text-4xl font-semibold text-center">Settings</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg space-y-8">
          <UserInfoDisplay
            user={{
              artistName,
              isArtist,
              hasStudio,
              isSeller,
              artistLink,
              stripeDashLink,
            }}
          />
        </div>
        {displayStorage()}
      </div>
      <div className="text-center space-y-4">
        <WideButton text={"sign out"} lambda={handleSignout} />
        <ContactEmail email="samir@dotmixtape.com" />
      </div>
    </div>
  );
}

export default Settings;