import React, { useContext } from "react";
import APIConfig from "../APIConfig";
import logo from "../assets/mxt_logo_small.png"; // Tell webpack this JS file uses this image
// import phaze_logo from "../assets/phaze-svg-1.svg";
import phaze_logo from "../assets/phaze-logo-ii.svg";
import Container from "./themes/Container";
import LogoContainer from "./themes/LogoContainer";

//(logo); // /logo.84287d09.png

const LogoView = () => {
  // Import result is the URL of your image
  var svg_logo = "https://storage.googleapis.com/tuareg/media/mxt-logo.svg";
  var text = ".mixtape";
  if (APIConfig.phaze() === true) {
    svg_logo = phaze_logo;
    text = "phaze";
  }
  var airtrak_logo = "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/app_assets%2Fimages%2Fairtrak-logo.svg?alt=media&token=4bec72f2-a13d-47a8-a27d-5ba10b5b10a9"
  
  if (APIConfig.airtrak() === true) {
    svg_logo = airtrak_logo;
    text = "airtrak";
  }

  if (APIConfig.phaze() === true) {
    return (
      <div className="grid place-items-center ">
        <img className="w-40 h-40" src={svg_logo} alt="Logo" />
      </div>
    );
  } else {
    return (
      // <LogoContainer className="">
      <div className="grid place-items-center ">
        <img className="w-40 h-40" src={svg_logo} alt="Logo" />

        <div className=" dark:text-white font-theFutureMonoRegular py-5">
          {"   "}
          {text}
        </div>
      </div>
      // </LogoContainer>
    );
  }
};

export default LogoView;
