// LinkedArtistsView.jsx

// LinkedArtistsView.jsx
import React from "react";
import ArtistsList from "./views/ArtistsList";
import useProfileInfo from "../hooks/useProfileInfo";
import LightSpinner from "../../../loading/LightSpinner";

const LinkedArtistsView = () => {
  const { artists, loading, error } = useProfileInfo();

  if (loading)
    return (
      <div className="font-theFutureBold text-xl font-semibold text-green-300 py-10">
        <LightSpinner />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;
  if (!artists.length) return <div>No linked artists found.</div>;

  return (
    <div>
      <div className="font-theFutureBold text-xl font-semibold text-green-300 mb-2">
        linked artists
      </div>
      {/* -- go to settings to add more  */}

      <ArtistsList artists={artists} />
    </div>
  );
};

export default React.memo(LinkedArtistsView);
