import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from "../../../../services/storage";
import drafting from "../../../../services/drafting";

const useAlbumLoader = (initialAlbum, location) => {
  const [album, setAlbum] = useState(initialAlbum);
  const [isLoading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [artistId, setArtistId] = useState(0);
  const navigate = useNavigate();

  const finalize = useCallback(async () => {
    const _album = await storage.validateDraft(album);
    if (_album.isValid) {
      navigate("/publish/album", { state: { album: _album } });
    } else {
      alert("Finish creating the album first");
    }
  }, [album, navigate]);

  const loadDraft = useCallback(async (docId) => {
    const album_draft = await drafting.getDraft(docId);
    setArtistId(album_draft.artist_id);
    const tracksArray = Object.values(album_draft.tracks).map((song) => ({
      title: song.track_name,
      artist: album_draft.artist,
      audioSrc: song.url,
    }));
    setTracks(tracksArray);
    setAlbum(album_draft);
  }, []);

  const loadAlbum = useCallback(async () => {
    if (location.state && location.state.album) {
      // Use the album from location state
      setAlbum(location.state.album);
      await loadDraft(location.state.album.docId);
    } else {
      // Fallback to loading the most recent draft if no album is passed
      const draft = await storage.getRecentDrafts();
      await loadDraft(draft.docId);
    }
    setLoading(false);
  }, [location.state, loadDraft]);

  return { album, isLoading, tracks, artistId, finalize, loadAlbum };
};

export default useAlbumLoader;