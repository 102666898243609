import React, { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import useImageUpload from "../../hooks/useImageUpload";
import useAlbumDraftCover from "../../hooks/useAlbumDraftCover";

// Utility function to check for valid image URL
const isValidImageUrl = (url) => {
    return url !== "" && url !== null;
  };

const AlbumDraftImageUploader = ({ album }) => {
  const [files, setFiles] = useState([]);
  const { uploadImage, progress, uploadStatus } = useImageUpload(album);
  const {
    url: albumCoverUrl,
    loading: coverLoading,
    refetch: refetchAlbumCover,
  } = useAlbumDraftCover(album);

  useEffect(() => {
    if (uploadStatus === "success") {
      refetchAlbumCover();
    }
  }, [uploadStatus, refetchAlbumCover]);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    for (const file of selectedFiles) {
      await uploadImage(file, album);
    }
    setFiles([]);
  };

  return (
    <div className="mt-4 flex justify-center">
      <form className="w-full max-w-xs">
        <label
          htmlFor="file-upload"
          className="cursor-pointer border-2 border-dashed border-gray-300 rounded-lg p-4 text-center block"
        >
          {isValidImageUrl(albumCoverUrl) && !coverLoading ? (
            <img
              src={albumCoverUrl}
              alt="Album Cover"
              className="object-cover rounded-lg mx-auto max-h-40"
            />
          ) : coverLoading ? (
            <div className="flex flex-col items-center justify-center h-40">
              <span className="text-gray-500 text-sm">Loading cover...</span>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-40">
              <PhotoIcon className="h-6 w-6 text-gray-500" />
              <span className="text-gray-500 text-sm">Upload Cover</span>
            </div>
          )}

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            multiple
            id="file-upload"
            className="hidden"
          />
        </label>
        {uploadStatus === "uploading" && (
          <div className="text-center mt-2">Progress: {progress}%</div>
        )}
      </form>
    </div>
  );
};

export default AlbumDraftImageUploader;
