import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ size = 8, color = 'gray-900', darkColor = 'white', borderWidth = 2 }) => {
    const dualToneStyle = `border-t-transparent`;
    const spinnerStyle = `animate-spin rounded-full h-${size} w-${size} border-${borderWidth} border-${color} dark:border-${darkColor} ${dualToneStyle} ease-linear transition-all duration-300`;

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      setIsVisible(true);
      return () => setIsVisible(false);
    }, []);

    const containerStyle = isVisible ? 'opacity-100' : 'opacity-0';

    return (
        <div className={`flex justify-center items-center transition-opacity duration-500 ${containerStyle}`}>
            <div className={spinnerStyle}></div>
        </div>
    );
};

Spinner.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    darkColor: PropTypes.string,
    borderWidth: PropTypes.number
};

export default Spinner;
