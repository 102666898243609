import React, { useState } from "react";

const UsernameEntryTextField = (props) => {
  const [username, setUsername] = useState(props.initialUsername || "");
  const [inputValid, setInputValid] = useState(false);
  const [displayErrorCaption, setDisplayErrorCaption] = useState(false);
  const nameRegex = /^[a-zA-Z0-9_.]{3,30}$/;

  const validateUsername = (username) => nameRegex.test(username);

  const handleUsernameChange = (event) => {
    const newValue = event.target.value.trim();
    setUsername(newValue);

    // Clear the error when user starts typing
    if (newValue === "") {
      setDisplayErrorCaption(false);
      props.setInputValidity(false); // Reset the validity
      props.setAvailable(true); // Reset availability
    } else {
      const isValid = validateUsername(newValue);
      setInputValid(isValid);
      props.setInputValidity(isValid);
      if (newValue.length >= 3) {
        setDisplayErrorCaption(!isValid);
      } else {
        setDisplayErrorCaption(false);
      }
    }

    props.onUsernameChange(newValue);
  };

  const handleSubmit = () => {
    if (inputValid) {
      props.onUsernameSubmit(username);
    } else {
      setDisplayErrorCaption(true);
    }
  };

  return (
    <div className="flex flex-col items-center font-sans text-black dark:text-white bg-white dark:bg-black w-full max-w-md">
      <div className="w-full mb-4">
        <input
          type="text"
          placeholder="Enter username"
          className="w-full bg-inherit font-sans dark:bg-transparent p-2 border-blue-500 border-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={handleUsernameChange}
          value={username}
          disabled={props.loading}
        />
      </div>
      <button
        className="bg-green-600 font-sans h-10 w-full rounded text-white hover:bg-green-700 transition duration-300"
        onClick={handleSubmit}
        disabled={props.loading}
      >
        {props.loading ? "Checking..." : "Continue"}
      </button>
      <div className="mt-2 h-6">
        {displayErrorCaption && (
          <div className="text-red-500 font-sans">Username is invalid</div>
        )}
      </div>
      <div className="py-2 font-sans text-sm text-gray-600 dark:text-gray-400">
        {displayErrorCaption && (
          <div>
            Please enter a username that is between 3-30 characters long and contains only letters (English alphabet), numbers, periods, and underscores. Spaces are not allowed.
          </div>
        )}
      </div>
    </div>
  );
};

export default UsernameEntryTextField;