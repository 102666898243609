// StorageUsage.js
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import { useUserStorage } from "./useStorageHooks";


const auth = firebase.auth();

const StorageUsage = () => {
  const [user] = useAuthState(auth);
  const [userStorage] = useUserStorage(user?.uid);

  const renderStorageUsage = () => {
    if (!userStorage) return null;

    const { usedStorage, storageLimit } = userStorage;
    const storagePercentage = (usedStorage / storageLimit) * 100;

    return (
      <div className="flex flex-col items-center">
        <div className="text-xl font-semibold mb-2">Storage Usage</div>
        <div className="w-full h-4 bg-gray-300 rounded">
          <div
            className="h-full bg-blue-500 rounded"
            style={{ width: `${storagePercentage}%` }}
          ></div>
        </div>
        <div className="text-sm mt-2">
          {Math.round((usedStorage / 1024 / 1024) * 100) / 100} MB /{" "}
          {Math.round((storageLimit / 1024 / 1024) * 100) / 100000} GB
        </div>
      </div>
    );
  };

  return <div className="p-4">{renderStorageUsage()}</div>;
};

export default StorageUsage;
