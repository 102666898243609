import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import api from '../../../api';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react'; // For smoother transitions

const AddArtistComponent = () => {
  const [artistName, setArtistName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(getAuth());
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // Show confirmation message

  const handleAddArtist = async () => {
    if (!artistName.trim()) return;
    setIsLoading(true);
    try {
      const response = await api.newArtist(artistName, user?.uid);
      const newArtist = await response.json();
      setIsLoading(false);
      setArtistName('');
      setIsOpen(false); // Close form after adding artist
      setShowSuccess(true); // Show success message
      setTimeout(() => setShowSuccess(false), 3000); // Hide success after 3 seconds
    } catch (error) {
      console.error('Error adding new artist:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4 mb-6">
      {/* Toggle button for opening/closing the form */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md mb-2 transition duration-150 ease-in-out hover:bg-blue-600 shadow-sm hover:shadow-md"
      >
        {isOpen ? (
          <>
            <MinusIcon className="h-4 w-4 mr-2" />
            Cancel
          </>
        ) : (
          <>
            <PlusIcon className="h-4 w-4 mr-2" />
            Add New Artist
          </>
        )}
      </button>

      {/* Animated collapse section */}
      <Transition
        show={isOpen}
        enter="transition-all duration-300 ease-in-out"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-screen opacity-100"
        leave="transition-all duration-300 ease-in-out"
        leaveFrom="max-h-screen opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        <div className="flex items-center space-x-3">
          <input
            type="text"
            value={artistName}
            onChange={(e) => setArtistName(e.target.value)}
            placeholder="New artist name"
            className="flex-grow text-gray-700 p-3 border border-gray-200 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={isLoading || !user}
          />
          <button
            onClick={handleAddArtist}
            className={`transition duration-150 ease-in-out transform hover:scale-105 bg-blue-500 text-white rounded-md px-6 py-2 shadow-sm focus:outline-none focus:ring focus:ring-blue-300 ${
              isLoading || !artistName.trim() || !user ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isLoading || !artistName.trim() || !user}
          >
            {isLoading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
            ) : (
              'Add Artist'
            )}
          </button>
        </div>
      </Transition>

      {/* Success message */}
      {showSuccess && (
        <p className="text-green-500 mt-2">Artist added successfully!</p>
      )}
    </div>
  );
};

export default AddArtistComponent;