import React from 'react';
import { useLocation } from 'react-router-dom';
import APIConfig from '../../../APIConfig'; // Corrected path
import NavLinkItem from './NavLinkItem';
import CreateEventButton from './CreateEventButton';
import ProfileMenu from './ProfileMenu';

const MenuItems = ({ user, altAuthPath, isRoot }) => {
  const location = useLocation();
  const onEventPage = location.pathname.startsWith('/e/');
  const showKonsoleButton = user && (APIConfig.airtrak() || APIConfig.studio());

  return (
    <div className="flex space-x-4 justify-end w-full">
      {/* {showKonsoleButton && (
        <NavLinkItem to="/konsole">konsole</NavLinkItem>
      )} */}
      {user && (
        <>
          {/* <CreateEventButton /> */}
          <ProfileMenu user={user} />
        </>
      )}
      {!user && (isRoot || onEventPage) && (
        <NavLinkItem to="/signin">sign in</NavLinkItem>
      )}
      {!user && altAuthPath && !isRoot && !onEventPage && (
        <NavLinkItem to={altAuthPath.path}>{altAuthPath.text}</NavLinkItem>
      )}
    </div>
  );
};

export default MenuItems;