import React from "react";



import DraftListView from "../DraftListView";
import useRealTimeDrafts from "../drafting/hooks/useRealTimeDrafts";
import { useAuth } from "../../../AuthContext";

const ModularSidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = useAuth();
  const { drafts, loading, error } = useRealTimeDrafts(user);

  return (
    <div>
      {!isOpen && (
        <button
          onClick={toggleSidebar}
          className="fixed text-white bg-blue-500 p-2 rounded z-30 mt-4"
        >
          Open Sidebar
        </button>
      )}
      <div
        className={`${
          isOpen ? "fixed" : "hidden"
        } inset-y-0 left-0 bg-gray-900 p-4 w-64 transition-transform duration-300 ease-in-out lg:fixed`}
      >
        <div className="flex flex-col h-full">
          <button
            onClick={toggleSidebar}
            className="text-white mb-6 hover:bg-blue-600 p-2 rounded focus:outline-none"
          >
            Close Sidebar
          </button>

          {/* Sidebar content - Handle loading and errors */}
          <h2 className="text-white text-lg font-semibold mb-4">Drafts</h2>
          {loading ? (
            <div className="text-white">Loading drafts...</div>
          ) : error ? (
            <div className="text-red-500">Error loading drafts</div>
          ) : (
            <DraftListView drafts={drafts} />  // Using your existing DraftListView
          )}
        </div>
      </div>
    </div>
  );
};

export default ModularSidebar;