import React, { useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CogIcon, UserIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { firebaseAuth } from '../../../provider/AuthProvider';

const ProfileMenu = ({ user }) => {
  const { handleSignout } = useContext(firebaseAuth);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center space-x-2">
        <UserIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
        <span className="font-mono text-sm text-gray-800 dark:text-gray-200">{user.displayName || 'Profile'}</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white dark:bg-black border-2 border-gray-200 dark:border-gray-700 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/settings"
                  className={`${
                    active ? 'bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-200'
                  } block px-4 py-2 text-sm`}
                >
                  <CogIcon className="h-5 w-5 inline mr-2" />
                  Settings
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleSignout}
                  className={`${
                    active ? 'bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-200'
                  } block px-4 py-2 text-sm w-full text-left`}
                >
                  <ArrowLeftIcon className="h-5 w-5 inline mr-2" />
                  Sign Out
                </button>
              )}
            </Menu.Item>
            {/* Add more menu items here */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;