import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WideButton from "../buttons/WideButton";
import DraftListView from "./DraftListView";
import LoadingSkeleton from "./LoadingSkeleton";
import LinkedArtistsView from "./dashboard/album_creator/LinkedArtistsView";
import storage from "../../services/storage";
import profile_service from "../../services/profile_service";
import { useAuth } from "../../AuthContext";
import useRealTimeDrafts from "./drafting/hooks/useRealTimeDrafts";

const Konsole = () => {
  const { user } = useAuth();
  const { drafts, loading, error } = useRealTimeDrafts(user);
  const navigate = useNavigate();

  const [artistName, setArtistName] = useState("");
  const [username, setUsername] = useState("");
  const [isEditMode, setIsEditMode] = useState(false); // New edit mode state

  // Fetch user profile information
  useEffect(() => {
    const fetchProfileInfo = async () => {
      if (user) {
        try {
          console.log("Fetching profile info for user:", user.uid);
          const idToken = await user.getIdToken(true);
          const response = await profile_service.getProfileInfo(idToken);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Profile data received:", data);

          if (data.artists && Array.isArray(data.artists) && data.artists.length > 0) {
            setArtistName(data.artists[data.artists.length - 1].name);
            setUsername(data.username || "user");
          } else {
            setArtistName("no artist");
            setUsername("user"); // Ensure username is set even if no artist
          }
        } catch (err) {
          console.error("Error fetching profile info:", err);
        }
      }
    };

    fetchProfileInfo();
  }, [user]);

  const handleNewAlbum = useCallback(
    async (albumName) => {
      try {
        const album = await storage.initializeAlbum(albumName);
        navigate("/create/album", { state: { album } });
      } catch (err) {
        console.error("Error creating new album:", err);
      }
    },
    [navigate]
  );

  const renderWelcomeMessage = useCallback(() => {
    if (!username) return null;

    return (
      <div className="pl-2 md:pl-4 lg:pl-4">
        <div className="text-green-400 font-theFutureMonoRegular">
          Welcome, @{username}
        </div>
      </div>
    );
  }, [username]);

  const renderNewAlbumButton = useCallback(
    () => (
      <WideButton
        text="Album +"
        lambda={() => handleNewAlbum(`Untitled ${drafts.length}`)}
      />
    ),
    [handleNewAlbum, drafts.length]
  );

  const renderDraftingPanel = useCallback(
    () => (
      <div className="pl-4 md:pl-4 lg:pl-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold text-gray-100">Drafts</h2>
          <button
            onClick={() => setIsEditMode(!isEditMode)} // Toggle edit mode
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            {isEditMode ? "Done" : "Edit"}
          </button>
        </div>
        <DraftListView drafts={drafts} isEditMode={isEditMode} />
        {renderNewAlbumButton()}
      </div>
    ),
    [drafts, renderNewAlbumButton, isEditMode]
  );

  const renderContent = () => {
    if (loading) {
      return <LoadingSkeleton />;
    }

    if (error) {
      return <div className="text-red-500">Error: {error.message}</div>;
    }

    if (artistName === "no artist") {
      return (
        <div className="text-yellow-500">
          No artist linked. Please go to settings to register an artist.
        </div>
      );
    }

    return renderWelcomeMessage();
  };

  useEffect(() => {
    if (!user) {
      navigate("/home");
    }
  }, [user, navigate]);

  return (
    <div className="py-4 px-4 md:px-8 lg:px-12">
      {renderContent()}
      {renderDraftingPanel()}
      <div className="mt-4">
        <LinkedArtistsView />
      </div>
    </div>
  );
};

export default Konsole;